<template>
  <section>
    <el-dialog v-model="dialogShow" 
      :visible.sync="dialogShow"
               @closed="checkclose"
               :close-on-press-escape="false"
               :close-on-click-modal="false"
               style="width: 120%; left:-8%; top:-80px"
               center>
      <template slot="title">
        <span style="color:#FFFFFF;font-size: 16px;position:relative;top:-3px;">{{myheadText}}</span>
      </template>
      
         <div class="el-dialog-div">
        <el-divider style="margin-top:-5px;" content-position="center">问题详情</el-divider>
      <el-form :model="checkForm" label-width="80px">
        <el-form-item label="会议名称">
          <el-input v-model="checkForm.MeetingName" :readonly="true"></el-input>
        </el-form-item>
        <el-form-item label="问题分类">
          <el-input :value="formatQuestionType(checkForm.QuestionType)" :readonly="true"></el-input>
        </el-form-item>
        <el-form-item label="问题名称">
          <el-input v-model="checkForm.QuestionName" :readonly="true"></el-input>
        </el-form-item>
        <el-form-item label="问题描述">
          <el-input type="textarea" v-model="checkForm.QuestionDescription" :readonly="true"></el-input>
        </el-form-item>
      </el-form>
      <el-divider content-position="center">讨论区</el-divider>
      <el-empty v-if="reviews.length<=0" description="讨论区暂无数据，率先发表你的看法吧"></el-empty>
       <el-card class="box-card" style="margin-top:10px" v-for="(r, index) in reviews " :key="r.Id">
        <div slot="header" class="clearfix">
          <span style="font-size:10px;cursor:default;">{{r.CreatedDpt}} {{r.CreatedBy}} {{r.CreateTime}}</span>
          <el-popover
            placement="bottom"
            width="370"
            trigger="manual"
            v-model="r.reply"
             >
             <editor style="width:auto; " :catchData="catchData" />
            <div style="position:absolute;bottom:12px;color:#999;font-size:7px;"><i class="el-icon-user-solid"></i>{{myname()}}</div>
            <el-button type="primary" style="float:right;margin-top:5px" size="mini" @click="onSendReplyReview(r)">回复评论</el-button>
            <el-button style="float:right;margin-top:5px;margin-right:5px" type="normal" size="mini" @click="handleCloseReply(r)">取消</el-button>

            <el-button  slot="reference" @click="handleOpenReply(r)" style="float: right; padding: 3px 0" type="text"><i class="el-icon-position"></i> 回复</el-button>
          </el-popover>
        </div>
        <div v-html="r.ReviewContent"></div>
        <div v-if="getReplyReview(reviews,r.ReplayID).CreatedBy" style="margin-left:22px">
          <p style="font-size:10px;"><font style="color:red;">[回复]</font> {{getReplyReview(reviews,r.ReplayID).CreatedDpt}} {{getReplyReview(reviews,r.ReplayID).CreatedBy}} {{getReplyReview(reviews,r.ReplayID).CreateTime}} </p>
          <div v-html="getReplyReview(reviews,r.ReplayID).ReviewContent"></div>

        </div>
      </el-card>

      <el-divider content-position="center">发表我的看法</el-divider>
      <div >
        <editor style="width:auto; " :catchData="catchData" />
        <div style="margin-top:5px;color:#999;font-size:7px;"><i class="el-icon-user-solid"></i>{{myname()}}</div>
      </div>
      <div slot="footer"
           class="dialog-footer" style="text-align: center;">
        <el-button type="primary" @click="onSendReview" icon="el-icon-position">发表评论</el-button>
        <el-button @click="dialogShow = false" >关闭</el-button>
      </div>
     </div>
    </el-dialog>

  </section>
</template>
<script>
import util from '../../util/date';
import { Loading } from 'element-ui'
import {
  AddMeetingQuestionReview,
  AddMeetingQuestion,
  QueryAboutMeMeetingList,
  QueryReviewByQuestionId,
  QueryPageMeetingQuestionListByUsercode,
  DeleteMeetingQuestionById,
  UpdateMeetingQuestion } from '../api/oa';
import editor from "../components/editor.vue";
export default {
  name: 'taskinfo',
  components: { editor, util },
  data () {
    return {
      popo1:false,
      CommonTaskClassifyText:'',
      loading: '',
      taskid: '',
      currentYearplanActiveId: null,
      currentActiveId: null,
      // editor: null,
      myheadText:'参与会议问题讨论',
      mytype:'normal',
      checkForm: {
        Id: null,
        WorkItemType: null,
        CommonTaskClassify: null,
        WorkItem: null,
        WorkDescription: null,
        PlanComplateTime: null,
        Remark: null,
        IsNeedApproval: null,
        Files: []
      },
      meetingForm: {
        Id: null,
      },
      addProgressForm: {            //进度新增表单初始化
        ProgressDescription: null,
        CreatedBy: null,
        CreatedId: null
      },
      addProgressFormRule: {
        ProgressDescription: [
          { required: true, message: '请输入进度描述', trigger: 'blur' }
        ]
      },
      WorkItemType: null,
      activeName: 'tabTask',
      pValue1: 0,   //进度条数值
      pValue2: 0,
      taskprogress: [],
      istaskpro: true,
      dialogShow:false,
      likeing:false,
      addProgressVisible: false,
      progresscheck:false,
      operation1: true,
      fileList: [],
      key: 0,
      yearplans:[],
      reviews:[],
      mapReview:new Map()
    }
  },
  props: ['headerText','data','type'],// 接收父组件的方法
  watch: {
    content () {
      // this.editor.txt.html(this.content)
    }
  },
  methods:
  {
    handleCloseReply(a)
    {
      a.reply = false;
      this.$forceUpdate()
    },
    handleOpenReply(a)
    {
      this.reviews.forEach(element => {
        if(element.Id != a.Id)
        {
          element.reply = false;
        }
      });
      a.reply = !a.reply;
      this.$forceUpdate()
    },
    myname()
    {
      var user = JSON.parse(window.localStorage.user);
      var dptname = window.localStorage.deptName;
      return dptname + " "+user.name;
    },
    getReplyReview(arr,id)
    {
      // console.log(arr);
      // console.log(id);
      var o ={};
      for(var i=0;i<arr.length;i++)
      {
        if(arr[i].Id==id)
        {
          o = arr[i];
          break;
        }
      }
      return o;
    },
    onSendReplyReview(review)
    {
      // console.log(review);
      let para = Object.assign({}, {});
      var user = JSON.parse(window.localStorage.user);
      para.CreatedId = user.sub;
      para.CreatedBy = user.name;
      para.CreatedOn = new Date();
      para.MeetingId = this.checkForm.MeetingId;
      para.QuestionId = this.checkForm.QuestionId;
      para.ReplayID = review.Id;
      para.ReviewContent = this.content;
      para.CreatedDptId = window.localStorage.deptId;
      para.CreatedDpt = window.localStorage.deptName;
      this.Loadingstart();
      AddMeetingQuestionReview(para).then(res => {
        if (res.data.success) {
          this.$message({
            message: res.data.msg,
            type: "success"
          });
          for(var a=0;a<this.reviews.length;a++)
          {
            if(this.reviews[a].Id==review.Id)
            {
              this.reviews[a].reply = false;
              break;
            }
          }
          this.$forceUpdate()
          this.loadReview();
          this.Loadignend();
        } else {
          this.$message({
            message: res.data.msg,
            type: "error"
          });
          this.Loadignend();
        }
      });
    },
    onSendReview()
    {
      let para = Object.assign({}, {});
      var user = JSON.parse(window.localStorage.user);
      para.CreatedId = user.sub;
      para.CreatedBy = user.name;
      para.CreatedOn = new Date();
      para.MeetingId = this.checkForm.MeetingId;
      para.QuestionId = this.checkForm.QuestionId;
      para.ReviewContent = this.content;
      para.CreatedDptId = window.localStorage.deptId;
      para.CreatedDpt = window.localStorage.deptName;
      this.Loadingstart();
      AddMeetingQuestionReview(para).then(res => {
        if (res.data.success) {
          this.$message({
            message: res.data.msg,
            type: "success"
          });
          this.loadReview();
          this.Loadignend();
        } else {
          this.$message({
            message: res.data.msg,
            type: "error"
          });
          this.Loadignend();
        }
      });
    },
    loadReview()
    {
      QueryReviewByQuestionId({
        questionId: this.checkForm.QuestionId
      }).then(res => {
        // console.log(res);
        this.reviews = res.data.response;
        // console.log(this.reviews)
      });
    },
    formatQuestionType: function(QuestionType) {
      // console.log(QuestionType)
      if(QuestionType==1)
      {
        return '售前咨询';
      }
      else if(QuestionType == 2)
      {
        return '售中支持';
      }
      else if(QuestionType == 3)
      {
        return '售后服务';
      }
      else if(QuestionType == 4)
      {
        return '资质问题';
      }
      else if(QuestionType == 5)
      {
        return '产销对接';
      }
      else if(QuestionType == 6)
      {
        return '人事管理';
      }
      else if(QuestionType == 7)
      {
        return '财务管理';
      }
      else if(QuestionType == 8)
      {
        return '信息化服务';
      }else{
        return '';
      }
    },
    catchData(c)
    {
      // console.log(c)
      this.content=c;
    },
    handlePopoShow()
    {
      
    },
    handlePopoReplyShow()
    {

    },
    handleChangeReview(val)
    {
      // console.log(val)
      if(val.length>0)
      {
        var progressId = val[0];
        this.loadReview(progressId);
      }
    },
    checkclose () {
      this.pValue1 = 0;
      this.$emit("closeFunction", {});
    },
    Loadingstart () {
      this.loading = Loading.service({
        lock: true,
        text: '加载中...',
        background: 'rgba(0,0,0,0.1)'
      })
    },
    Loadignend () {
      this.loading.close();
    },
    loadMeeting()
    {
      QueryMeetingById({Id:this.checkForm.MeetingId}).then((res)=>{
        // console.log(res);
        Object.assign(this.meetingForm,res.data.response);
      })
    },
    handleFormatTime(t)
    {
      return t;
    },
    handleProcessSelectChange (taskid, index) {
      this.currentActiveId = taskid;
      this.currentProgressRowIndex = index;
    },
  },
  mounted () {
    if(this.headerText)
    {
      this.myheadText = this.headerText;
    }
    if(this.type)
    {
      this.mytype = this.type;
      console.log(this.mytype)
    }
    if(this.data)
    {
      var row = this.data;
      // console.log(row);
      this.checkForm = Object.assign({Files:[]}, row);
      // console.log(this.checkForm)
      this.dialogShow = true;
      this.loadReview();
      
    }
    
  }
}
</script>
<style scoped>
.active {
  background-color: #00000014;
}
.el-dialog-div{
    height: 75vh;
     overflow: auto;
    }
</style>